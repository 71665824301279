@import "../../../styles/variables";



.bodyInfo {
    padding-left: 30px;
    padding-right: 20px;
    padding-top: 40px;
}

.cardSearch {
    background-color: #E6F8FA;
    border-radius: 6px;
    border: 1px solid #00B4CC;
    padding: 8px 15px;
}

.bodyRegisterInfo {
    min-height: calc(100vh - 190px);
    overflow: auto;
}

.bodyRegisterInfoReading {
    min-height: calc(100vh - 207px);
    overflow: auto;
}

.sectionDisabled {
    display: none
}

.sectionBorder {
    background: #fff;
    border-left: 3px solid #00B4CC;

}

.blueFilter {
    filter: invert(63%) sepia(72%) saturate(4202%) hue-rotate(149deg) brightness(96%) contrast(101%);
}

.fullScreen {
    height: 100%;
    width: 100%;
}

.subtitleColor {
    color: $darkblue_60
}



.lineCards {
    margin: 0.2rem 0px;
    margin-bottom: 1rem;
}

.patientList{
    overflow-y: auto;
    height: 15rem;
}