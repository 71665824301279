@import "../../../../../../styles/variables";

.textconfig-modal {

    @at-root #{&}__tabs {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @at-root #{&}__tab {
        padding: 10px 25px;
        background-color: $white;
        color: $primary_color;
        font-weight: bold;
        border-radius: $border_radius_normal;
        cursor: pointer;

        &.disabled {
            background-color: #eee !important;
            color: #bbb !important;
            cursor: default;
        }

        &.active {
            background-color: $turquoise_10;
        }

        &:hover {
            background-color: $turquoise_10;
        }
    }

    @at-root #{&}__table-wrapper {
        width: 100%;

        tbody {
            display: block;
            width: 100%;
            overflow-y: auto;
            max-height: 100px;
            cursor: pointer;
        }

        thead tr {
            display: block;
        }
    }
}