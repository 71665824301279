@import "@/styles/variables";

.preconsultation {
    display: flex;
    background: $turquoise_5;
    width: 100%;
    flex: 1;
    overflow: hidden;

    @at-root #{&}-main {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    @at-root #{&}-tabs {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    @at-root #{&}-tab {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px;
        flex: 1;
        color: $turquoise_40;
        border: 2px solid $turquoise_40;
        border-radius: $border_radius_normal;
        font-weight: 700;
        background-color: $turquoise_10;
        cursor: pointer;
        user-select: none;
        white-space: nowrap;
        padding: 0 10px;

        &.active,
        &:hover {
            background-color: $primary_color;
            color: $white;
        }

        svg {
            width: 20px;
            margin-right: 5px;
        }
    }

    @at-root #{&}-quest {
        display: flex;
        align-items: flex-end;
        height: 40px;
    }
}

.IconSearch {
    position: absolute;
    bottom: 10px;
    right: 20px;
    border-radius: 100%;
    transition: all 0.3s;
    padding: 5px;
    color: $turquoise_100;

    &:hover {
        color: white;
        background-color: $turquoise_100;
    }
}

.dynamic-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    animation: scaleIcon 500ms infinite alternate ease-in-out;
    color: $white;
    cursor: pointer;

    svg {
        width: 20px;
    }

    @at-root #{&}__warning {
        background-color: $warning_color;
    }

    @at-root #{&}__danger {
        background-color: $danger_color;
    }

    @at-root #{&}__primary {
        background-color: $primary_color;
    }
}

@keyframes scaleIcon {

    from {
        transform: scale(0.9);
    }

    to {
        transform: scale(1);
    }
}