@import "../../../styles/variables";

.sidebar {
    width: 80px;
    height: calc(100% - 60px);
    box-shadow: 8px 0px 18px -6px rgba(0, 63, 128, 0.12);
    z-index: 500;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    white-space: nowrap;
    overflow: hidden;
    position: fixed;
    align-items: flex-start;
    left: 0;
    bottom: 0;
    background-color: $white;
    transition: all 500ms;

    &.active {
        width: 400px; 
    }

    @at-root #{&}-close {
        padding: 5px;
        margin-top: 12px;
        align-self: flex-end;
        cursor: pointer;
    }

    @at-root #{&}-menu {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        border: 3px solid $primary_color;
        color: $primary_color;
        border-radius: $border_radius_normal;
        cursor: pointer;
        margin-top: 10px;
        margin-left: 3px;

        &:hover {
            background-color: $primary_color;

            img {
                color: $white;
                filter: brightness(0) saturate(100%) invert(58%) sepia(69%) saturate(4712%) hue-rotate(154deg) brightness(102%) contrast(101%);
            }
        }

        img {
            width: 20px;
            height: 20px;
            stroke-width: 3px;
            filter: brightness(0) saturate(100%) invert(69%) sepia(11%) saturate(151%) hue-rotate(196deg) brightness(93%) contrast(87%);
        }
    }

    @at-root #{&}-menu__items {
        width: 100%;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        padding: 5px;
        overflow-x: hidden;
        overflow: hidden;
    }

    @at-root #{&}-menu__item {
        display: flex;
        align-items: flex-start;
        cursor: pointer;
        margin: 10px 0;

        &:hover {

            img {
                filter: brightness(0) saturate(100%) invert(58%) sepia(69%) saturate(4712%) hue-rotate(154deg) brightness(102%) contrast(101%);
            }

            .sidebar-menu__item-label {
                color: $turquoise_60 !important;
                border-color: $turquoise_60 !important;
            }
        }

        @at-root #{&}-label {
            color: $gray_60;
            font-size: 18px;
            width: 100%;
            text-decoration: none;
        }

        @at-root #{&}-list {

            li,
            a {
                list-style: none !important;
                text-decoration: none;
                font-size: 16px;
                color: $gray_60;

                &:hover {
                    color: $turquoise_60 !important;
                }
            }
        }

        img {
            min-width: 30px !important;
            min-height: 30px !important;
            filter: brightness(0) saturate(100%) invert(69%) sepia(11%) saturate(151%) hue-rotate(196deg) brightness(93%) contrast(87%);
        }
    }

}

.colorBlue{ 
    img {
        color: $white;
        filter: brightness(0) saturate(100%) invert(58%) sepia(69%) saturate(4712%) hue-rotate(154deg) brightness(102%) contrast(101%);
    }
}