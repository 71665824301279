@import "../../../styles/variables";

.header {
    width: 100%;
    min-height: 60px;
    display: flex;
    background-color: $white;
    border-bottom: 3px solid $primary_color;
    align-items: center;
    padding: 10px 20px;

    .circle {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: $darkblue_40;
        margin-left: 15px;
    }

    @at-root #{&}-title {
        font-size: 1.5rem;
        margin-left: 15px;
        font-weight: 700;
        flex: 1;
        color: $darkblue_40;
    }

    @at-root #{&}-detail {
        display: flex;
        align-items: center;
        margin-right: 30px;
        cursor: pointer;
        user-select: none;

        &:hover {

            span,
            svg {
                color: $primary_color;
            }
        }

        span {
            font-weight: 600;
            margin-top: 3px;
            color: #666;
        }

        svg {
            color: #666;
            margin-right: 5px;
        }
    }

    @at-root #{&}-account {
        border: 1px solid #ccc;
        width: 200px;
        height: 100%;
        border-radius: $border_radius_normal;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        position: relative;
        user-select: none;

        &:hover {
            background-color: $turquoise_5;
        }

        @at-root #{&}__labels {
            display: flex;
            align-items: center;

            span {
                font-weight: 600;
                margin-top: 3px;
                color: #666;
            }

            svg {
                color: #666;
                margin-right: 5px;
            }
        }

        @at-root #{&}__options {
            position: absolute;
            top: 45px;
            left: 0;
            width: 100%;
            border: 1px solid #ccc;
            box-shadow: $box_shadow;
            background: white;
            z-index: 100;
            border-radius: $border_radius_normal;
            padding: 5px 0;
            user-select: none;

            @at-root #{&}-item {
                padding: 5px 10px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                cursor: pointer;
                text-decoration: none;
                color: inherit;
                display: block;

                &.selected {
                    background-color: $primary_color;
                    color: $white;

                    &:hover {
                        background-color: $primary_color;
                    }
                }

                &:hover {
                    background-color: $primary_color;
                    color: $white;
                }
            }
        }
    }

    @at-root #{&}-profile {
        width: 100%;
        display: flex;
        flex-direction: column;

        @at-root #{&}__info {
            background-color: $turquoise_5;
            border-radius: $border_radius_normal;
            height: 100%;
            padding: 10px 15px;
        }

        @at-root #{&}__selection {
            display: flex;
            flex-direction: column;
            max-height: 400px;
            flex-wrap: wrap;
        }
    }
}

.notification-dot {
    position: absolute;
    top: 0px;
    right: 24px;
    width: 7px;
    height: 7px;
    background-color: rgba(255, 0, 0, 0.507);
    border-radius: 50%;
}

.headerNotifications {
    position: absolute;
    top: 45px;
    right: 265px;
    width: 200px;
    border: 1px solid #ccc;
    box-shadow: $box_shadow;
    background: white;
    z-index: 100;
    border-radius: $border_radius_normal;
    padding: 5px 0;
    user-select: none;
}