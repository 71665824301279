.eye {
    width: 100%;
    height: 100%;

    background-image: url(../.././../../../assets/eye.png);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;

    position: relative;
}

.element {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #5C84AD;
    color: #fff;
    border-radius: 2px;
    z-index: 10;
}

.sectionExamEyeOD{
    background-color: #D6F3F7;
    border-radius: 6px;
    padding: 6px ;
}
.elemEyeOD{
    background-color: #D6F3F7; 
    padding: 4px 0px;
}
.elemEyeOI{
    background-color: #F3C68240; 
    padding: 4px 0px;

}
.sectionExamEyeOI{
    background-color: #F3C68240;
    border-radius: 6px;
    padding: 6px ;
}