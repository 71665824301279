@import "../../../../../../styles/variables";

.version,
.version-table {
    width: 100%;
    height: 15vw;
}

.version-table {
    table-layout: fixed;

    tr td {
        width: 2vw;
    }

    tr:first-child td:nth-child(2),
    tr:first-child td:nth-child(4),
    tr:first-child td:nth-child(6) {
        vertical-align: top;
    }

    tr:last-child td:nth-child(2),
    tr:last-child td:nth-child(4),
    tr:last-child td:nth-child(6) {
        vertical-align: bottom;
    }
}

.radio-button:hover {
    background-color: $secondary_color !important;
    color: $white !important;
}

.version-img {
  position: relative;
  div:nth-child(1),
  div:nth-child(2) {
    z-index: 0;
    display: flex;
    width: 100%;
    position: absolute;
    height: 100%;
    align-items: center;
    justify-content: center;
    pointer-events: none;
  }

  div:nth-child(1),
  div:nth-child(2) {
    &::after,
    &::before {
      content: "";
      background-color: #9eb9d6;
      width: 1.2px;
      position: absolute;
    }
  }

  div:nth-child(1) {
    &::before {
      height: 100%;
      transform: rotate(33deg);
    }

    &::after {
      height: 100%;
      transform: rotate(-33deg);
    }
  }

  div:nth-child(2) {
    &::before {
      height: 70%;
      transform: rotate(90deg);
    }

    &::after {
      height: 84%;
    }
  }
}