.sight-table {
    width: 100%;
    overflow: hidden;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    border-collapse: collapse;

    table tr td {
        cursor: pointer;
        border: 1px solid #dee2e6;
        height: 100px;

        &:hover {
            background-color: #f1f1f1;
        }
    }
}

.sight-table-readonly {
    width: 100%;
    overflow: hidden;

    table tr td:not(:first-child) {
        cursor: pointer;
        border-left: 1px solid #9da0a3;
        height: 100px;
    }

    table tr:not(:last-child) td {
        border-bottom: 1px solid #9da0a3 !important;
    }

    table tr:last-child td {
        border-bottom: none;
    }
}