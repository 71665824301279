@import "../../styles/variables";

.table-wrapper {
    width: 100%;
    color: $muted_color;
    border-radius: $border_radius_large;
    border-collapse: collapse;
    box-shadow: 0px 0px 0px 1px $turquoise_40;
    margin-bottom: 12px;
    z-index: 10;
    background-color: $white;

    thead tr th:first-child {
        border-top-left-radius: $border_radius_large;
    }

    thead tr th:last-child {
        border-top-right-radius: $border_radius_large;
    }

    tbody tr:last-child td:first-child {
        border-bottom-left-radius: $border_radius_large;
    }

    tbody tr:last-child td:last-child {
        border-bottom-right-radius: $border_radius_large;
    }

    tbody tr {
        border-top: 1px solid #eee;

        &:hover {
            background: #f9f9f9;
        }
    }

    th,
    td {
        padding: 8px 10px;
    }

    &.compact {
        td {
            font-size: 1rem;
            padding: 2px 8px;
        }
    }

    @at-root #{&}-primary {
        thead tr th {
            background-color: $turquoise_10;
            color: $primary_color;
            font-weight: 700;
            font-size: 1.2rem;
            text-transform: none;
        }
    }
}

.table-wrapper__scrollable {
    width: 100%;
    height: auto;
    thead,
    tbody,
    tr {
        display: table;
        width: 100%;
        >th {
            background-color: $turquoise_10;
            color: $primary_color;
            font-weight: 700;
            font-size: 1.2rem;
            text-transform: none;
            padding: 5px;

        }
    }

    tbody {
        display: block;
        overflow-y: auto;
        overflow-x: hidden;
        table-layout: fixed;
        max-height: 100px;

        >tr {
            border-top: 1px solid #eee;
            td{
                padding: 5px;

            }

            &:hover {
                background: #f9f9f9;
            }

        }
    }
}


.table-pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    @at-root #{&}__total {
        font-weight: 700;
        font-size: 1.2rem;
        color: #666;
    }

    @at-root #{&}__controls {
        display: flex;
        align-items: center;

        svg {
            color: $primary_color;
        }

        .number {
            font-weight: 700;
            font-size: 1.2rem;
            margin: 0 10px;
            color: #666;
        }
    }
}