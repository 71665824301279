@import "../../../styles/variables";


.diagnosticAids {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    background-color: $turquoise_5;
}

.cardGeneralInfo {
    background-color: #e8e8ea8a;
    border-radius: 6px;
    padding: 6px 4px;
}

.bodyRegisterInfo {
    min-height: calc(100vh - 190px);
    overflow: auto;
}

.bodyRegisterInfoReading {
    min-height: calc(100vh - 207px);
    overflow: auto;
}

.sectionDisabled {
    display: none
}

.backgroundSpaceSection {
    background: #fff;
    border-left: 4px solid #00B4CC;

}

.blueFilter {
    filter: invert(63%) sepia(72%) saturate(4202%) hue-rotate(149deg) brightness(96%) contrast(101%);
}

.fullScreen {
    height: 100%;
    width: 100%;
}

.subtitleColor {
    color: $darkblue_60
}

.pdfRender {
    border-radius: 10px;
    scrollbar-width: thin;
}

.pdfRender iframe::-webkit-scrollbar {
    width: 5px;
    /* Cambia el ancho de la barra de desplazamiento horizontal */
}

/* Estilo personalizado para la barra de desplazamiento vertical del iframe */
.pdfRender iframe::-webkit-scrollbar-thumb {
    background-color: #007bff;
    /* Cambia el color del dedo de la barra de desplazamiento */
}

.backgroundPdf {
    padding-left: 15px;
    background: white;
    margin-right: 20px;
    height: calc(100vh - 88px);
    // border: 2px solid #e8e8ea8a;
    // box-shadow: 2px 4px 8px 2px rgba(0, 63, 128, 0.04);

}

.lineCards {
    margin: 0.2rem 0px;
    margin-bottom: 1rem;
}

.controls {
    display: flex;
    align-items: center;
    width: 100%;
    max-height: 90px;
    background: white;
    padding: 1rem 3rem;
    z-index: 50;
    border-top: 1px solid rgba(0, 0, 0, 0.175);
}

// DILATATION STYLES

.preconsultation {
    display: flex;
    background: $turquoise_5;
    width: 100%;
    flex: 1;
    overflow: hidden;

    @at-root #{&}-main {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    @at-root #{&}-tabs {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    @at-root #{&}-tab {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px;
        flex: 1;
        color: $turquoise_40;
        border: 2px solid $turquoise_40;
        border-radius: $border_radius_normal;
        font-weight: 700;
        background-color: $turquoise_10;
        cursor: pointer;
        user-select: none;
        white-space: nowrap;
        padding: 0 10px;

        &.active,
        &:hover {
            background-color: $primary_color;
            color: $white;
        }

        svg {
            width: 20px;
            margin-right: 5px;
        }
    }

    @at-root #{&}-quest {
        display: flex;
        align-items: flex-end;
        height: 40px;
    }
}

.IconSearch {
    position: absolute;
    bottom: 10px;
    right: 20px;
    border-radius: 100%;
    transition: all 0.3s;
    padding: 5px;
    color: $turquoise_100;

    &:hover {
        color: white;
        background-color: $turquoise_100;
    }
}

.dynamic-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    animation: scaleIcon 500ms infinite alternate ease-in-out;
    color: $white;
    cursor: pointer;

    svg {
        width: 20px;
    }

    @at-root #{&}__warning {
        background-color: $warning_color;
    }

    @at-root #{&}__danger {
        background-color: $danger_color;
    }

    @at-root #{&}__primary {
        background-color: $primary_color;
    }
}

@keyframes scaleIcon {

    from {
        transform: scale(0.9);
    }

    to {
        transform: scale(1);
    }
}