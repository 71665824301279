@import "../../../../../../styles/variables";

.textarea__with-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-shadow: 0px 0px 0px 1px $turquoise_40;
    border-radius: $border_radius_normal;

    .textarea-header {
        width: 100%;
        height: 25px !important;
        background-color: $turquoise_10;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 10px;
        color: $primary_color;
        font-weight: bold;
    }
    
    textarea {
        padding: 5px 10px;
        border: 0;
        flex: 1;
        resize: none;
        outline: none;

        color: $muted_color;
    }
}