@import "../../../../../../styles/variables";

.tab-button {
    padding: 5px 10px;
    border-radius: $border_radius_normal;
    background-color: rgba($primary_color, $alpha: 0.2);
    color: $primary_color;
    margin-right: 10px;
    display: flex;
    align-items: center;
    font-weight: 600;
    user-select: none;
    cursor: pointer;
    flex-wrap: nowrap;
    white-space: nowrap;

    &:hover {
        background-color: rgba($primary_color, $alpha: 0.3);
    }

    &.isActive {
        color: white;
        background-color: $primary_color;
    }

    .delete-icon:hover {
        background-color: rgba(#FFF, $alpha: 0.3);
        border-radius: $border_radius_normal;
    }
}