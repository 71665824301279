@import "../../styles/variables";

.avatar {
    border-radius: 50%;
    outline: 1px solid $primary_color;
    overflow: hidden;
    min-width: fit-content;
    min-height: fit-content;
    
    img {
        object-fit: cover;
    }

    @at-root #{&}-small {
        img {
            height: 25px;
            width: 25px;
        }
    }

    @at-root #{&}-normal {
        img {
            height: 35px;
            width: 35px;
        }
    }

    @at-root #{&}-large {
        img {
            height: 40px;
            width: 40px;
        }
    }
    @at-root #{&}-extraLarge {
        img {
            height: 80px;
            width: 80px;
        }
    }
    
}