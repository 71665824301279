@import "../../styles/variables";

.fileText {
    background: $orange_40;
    max-width: 7rem;
}

.fileTextIcon {
    border-radius: 100%;
    padding: 0px 3px;
    display: block;
    height: 15px;

    &:hover {
        background: $gray_20;
        cursor: pointer;
        border-radius: 100%;
        padding: 0px 3px;
    }

    &:hover svg {
        filter: brightness(0) saturate(100%) invert(43%) sepia(8%) saturate(528%) hue-rotate(198deg) brightness(98%) contrast(86%);
    }
}