@import "../../styles/variables";

.select {
    border: 1px solid #ced4da;
    border-radius: $border_radius_normal;
    height: 30px;
    display: flex;
    align-items: center;
    background: $white;
    cursor: pointer;
    padding: 0 5px 0 10px;
    user-select: none;
    position: relative;
    background-color: $white;

    &.grouped {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    @at-root #{&}-plain {
        background-color: $white;
        border: 0;
        border-radius: 0;
        border-bottom: 2px solid #ccc;
        padding: 0;

        &:focus-within {
            border-bottom-color: $primary_color;
        }
    }

    @at-root #{&}-filled {
        background-color: $turquoise_10;
        border-color: $turquoise_10;

        &:focus-within {
            background: $turquoise_20;
            border-color: $turquoise_20;
        }
    }

    @at-root #{&}-disabled {
        background: $darkblue_5 !important;
        pointer-events: none;

        .select-disabled {
            color: #bbb;
        }
    }

    &:focus-within {
        border-color: $primary_color !important;
    }

    @at-root #{&}-placeholder {
        color: #aaa;
    }

    @at-root #{&}-input {
        border: 0;
        outline: 0;
        background-color: transparent;
        overflow: hidden;
        width: 100%;
    }

    @at-root #{&}-menu {
        position: absolute;
        top: 35px;
        left: 0;
        width: 100%;
        border: 1px solid #ccc;
        box-shadow: $box_shadow;
        min-height: 40px;
        background: white;
        z-index: 100;
        border-radius: $border_radius_normal;
        padding: 5px 0;
        overflow-y: auto;
        user-select: none;

        @at-root #{&}__item {
            padding: 5px 10px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &.selected {
                background-color: $primary_color;
                color: $white;

                &:hover {
                    background-color: $primary_color;
                }
            }

            &:hover {
                background-color: $turquoise_10;
            }
        }
    }

    svg {
        color: $darkblue_80;
        width: 15px;
    }
}

.isLoadingSelect {
        content: " ";
        filter: opacity(70%); 
        display: block;
        width: 14px;
        height: 14px;
        margin-right: 10px;
        border-radius: 50%;
        pointer-events: none; 
        border: 2px solid $primary_color;
        border-color: $primary_color transparent $primary_color transparent;
        animation: lds-dual-ring 1.2s linear infinite;
    }