@import "../../styles/variables";

.scrollable-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    border-radius: $border_radius_normal;
    border: 1px solid $turquoise_40;

    .table-head {
        background-color: $turquoise_10;
        height: 30px;
        border-bottom: 1px solid #ddd;

        .table-cell {
            color: $primary_color;
            font-weight: 700;
            display: block !important;
        }
    }

    .table-body {
        background-color: $white;
        overflow-y: auto;

        .row:not(:last-child) {
            border-bottom: 1px solid #ddd;
        }

    }

    .table-cell {
        color: #666;
        vertical-align: middle;
        font-size: 1rem;
        padding: 4px 10px;
        display: flex;
        align-items: center;
    }

}