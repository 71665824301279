@import "../../styles/variables";

.drawer-option {
    position: absolute;
    top: 0;
    background: $white;
    height: 100%;
    // padding: 40px 20px 30px 20px;
    box-shadow: $box_shadow;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.modal {

    @at-root #{&}-fullScreen {
        width: 100%;
        height: 100%;
        margin: 0 !important;
        padding: 40px 50px !important;
    }

    @at-root #{&}-wrapper {
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, .4);
        display: flex;
        justify-content: center;
        align-items: flex-start;
        overflow: hidden;
        z-index: 1000;
        position: fixed;
        top: 0;
        animation: animateOverlay 300ms ease-in-out;
        padding: 40px;

        body {
            overflow: hidden;
        }
    }

    @at-root #{&}-container {
        padding: 20px !important;
        border-radius: 8px;
        box-shadow: $box_shadow;
        background: $white;
        animation: animateModal 300ms ease-in-out;
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        position: relative;

        .close-button {
            position: absolute;
            top: 20px;
            right: 20px;
            z-index: 1000;
            cursor: pointer;
        }
    }


    @at-root #{&}-drawer__right {
        @extend .drawer-option;
        right: 0;
        animation: animateDrawerRight 300ms ease-in-out;
    }

    @at-root #{&}-drawer__left {
        @extend .drawer-option;
        left: 0;
        animation: animateDrawerLeft 300ms ease-in-out;
    }

    // @at-root #{&}-Tabs {
    //     width: 100%;
    //     height: 35px;
    //     display: flex;
    // }

    // @at-root #{&}-Tab {
    //     width: 100%;
    //     height: 100%;
    //     text-align: center;
    //     border-bottom: 2px solid $light_color_20;
    //     cursor: pointer;
    //     transition: 0.1s all ease-in-out;

    //     &.Modal-Tab__Active {
    //         border-color: $primary_color;
    //     }
    // }
}

@keyframes animateModal {
    from {
        transform: translateY(-30px);
    }

    to {
        transform: translateY(0px);
    }
}

@keyframes animateDrawerRight {
    from {
        transform: translateX(50px);
    }

    to {
        transform: translateX(0px);
    }
}

@keyframes animateDrawerLeft {
    from {
        transform: translateX(-50px);
    }

    to {
        transform: translateX(0px);
    }
}

@keyframes animateOverlay {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}