.hidden {
    display: none;
}

.buttonRecords {
    background: #fff;
    border-radius: 50%;
    border: none;
}

.recorder>.audio-recorder {
    background-color: #fff;
    width: 200px;
    height: 30px;
    box-shadow: none;
}

.recorder>.audio-recorder>.audio-recorder-timer {
    color: #6E6F7C;
}

.recorder>.audio-recorder>.audio-recorder-status {
    color: #6E6F7C;
}

.textTime{
    width: 100px;
}

.audioPlayer {
    border-radius: 3rem !important;
    box-shadow: none !important;
    width: 80% !important;

}

.blueFilter{
    filter: brightness(0) saturate(100%) invert(52%) sepia(86%) saturate(483%) hue-rotate(139deg) brightness(91%) contrast(103%);
}