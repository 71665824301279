@import "../../styles/variables";

@mixin filled_button($color) {
    background-color: $color !important;
    color: $white !important;

    &:hover:enabled {
        background-color: darken($color, $amount: 5%) !important;
    }

    &.isLoading::before {
        border: 2px solid #fff !important;
        border-color: #fff transparent #fff transparent !important;
    }
}

.button {
    border: 1px solid transparent !important;
    font-size: inherit !important;
    font-weight: 600 !important;
    padding: 0 15px !important;
    height: 30px !important;
    border-radius: $border_radius_normal !important;
    color: $turquoise_100;
    outline: none;
    user-select: none;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &.isLoading::before {
        content: " ";
        display: block;
        width: 14px;
        height: 14px;
        margin-right: 10px;
        border-radius: 50%;
        border: 2px solid $primary_color;
        border-color: $primary_color transparent $primary_color transparent;
        animation: lds-dual-ring 1.2s linear infinite;
    }

    &.isLoading {
        filter: opacity(70%);
        pointer-events: none;

        .tabler-icon {
            display: none;
        }
    }

    &:disabled {
        background-color: #eee !important;
        color: #bbb !important;
        border-color: #eee !important;
    }

    svg {
        width: 15px;
    }

    @at-root #{&}-text {
        background-color: transparent;
        color: #888;

        &:hover {
            color: $primary_color;
        }
    }

    @at-root #{&}-primary {
        @include filled_button($primary_color);
    }

    @at-root #{&}-outline {
        background: transparent !important;
        color: $primary_color !important;
        border-color: $primary_color !important;

        &:hover:enabled {
            background: rgba($primary_color, 0.1) !important;
        }
    }

    @at-root #{&}-secondary {
        @include filled_button($secondary_color);
    }

    @at-root #{&}-warning {
        @include filled_button($warning_color);
    }

    @at-root #{&}-danger {
        @include filled_button($danger_color);
    }

    @at-root #{&}-outline-danger {
        background-color: $white;
        color: $danger_color;
        border-color: $danger_color !important;

        &:hover:enabled {
            background: rgba($danger_color, 0.1) !important;
        }
    }

    @at-root #{&}-small {
        height: 30px;
        font-size: 1rem;
    }

    @at-root #{&}-normal {
        height: 35px;
    }

    @at-root #{&}-large {
        height: 40px;
        font-size: 1.2rem !important;

        svg {
            width: 18px;
        }
    }
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}