@import "../../../../styles/variables";

.consultation {
    padding: 30px 50px;

    @at-root #{&}-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    @at-root #{&}-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 1rem;
        margin-left: auto;
        margin-right: auto;
        background: $turquoise_5;
        overflow-y: auto !important;
    }
}

.services-list {
    width: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-top: 1px solid #ccc;

    @at-root #{&}__item {
        width: 100%;
        border-bottom: 1px solid #ccc;
        padding: 10px;

        &:hover {
            background-color: $turquoise_5;
        }
    }
}

.section-comments {
    width: 100%;
    height: 100%;
    max-height: 200px;
    border-radius: $border_radius_normal;
    background: $turquoise_10;
    border: 1px solid $primary_color;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    padding: 10px 20px 20px 20px;

    .form-control {
        resize: none !important;
        border: 1px solid #ccc !important;
        height: 100%;
    }
}