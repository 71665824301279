.workspace {
    display: flex;
    flex-direction: column;
    position: relative;

    width: 100%;
    height: 100%;

    @at-root #{&}-wrapper {
        margin-left: 80px;
        flex: 1;
        position: relative;
        display: flex;
        overflow: hidden;

        .svg {
            width: 600px;
            position: absolute;
            bottom: 30px;
            right: 30px;
        }
    }
}