.oftalmology {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
}

.sectionExamEyeOD{
    background-color: #D6F3F7;
    border-radius: 6px;
    padding: 6px ;
}
.sectionExamEyeOI{
    background-color: #F3C68240;
    border-radius: 6px;
    padding: 6px ;
}

.hidden{
    display: none;
}

.textareaDetailsExamsSection{
height: 19px !important;
}