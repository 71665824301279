.custom-audio-player {
    background-color: transparent;
    height: 30px;
    width: 90%;
}

.custom-audio {
    background-color: transparent;
}

.custom-audio::-webkit-media-controls-panel {
    background-color: white;
    height: 30px;
    width: 700px;
}

.custom-audio::-webkit-media-controls-enclosure {
    background-color: white;
    height: 30px;
    width: 700px;
}

.custom-audio::-webkit-media-controls-play-button {
    background-image: ((url(../../../assets/playPause.svg)));
}

.custom-audio::-webkit-media-controls-mute-button {
    display: none;
}
.custom-audio.custom-audio::-webkit-media-controls-volume-control-container.closed {
    display: none;
    width: 0;
}


.custom-audio::-webkit-internal-media-controls-overflow-button input[type="button" i] {
    background-image: none;
    width: 0;
}
.custom-audio::-internal-media-controls-volume-control-container {
    display: none;
    width: 0;
}

.textTime{
    width: 100px;
}



.custom-slider {
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    border-radius: 7px;
    background: #00B4CC; /* Color de la barra de progreso */
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s;
  }
  
  .custom-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #00B4CC;
    background: white; /* Color del círculo del slider */
    cursor: pointer;
  }

// audio::-internal-media-controls-overflow-button {
// background-image: url(../../../assets/cloud.svg);
// }


.hide{
    display: none;
}

// .custom-audio::-webkit-media-controls-timeline input[type="range"]{
//     /* Cambiar el color de la barra de progreso en Chrome */
//     background-color: blue; /* Cambia el color de fondo de la barra */
//   }
  
//   audio::-webkit-media-controls-play-progress {
//     /* Cambiar el color del progreso reproducido en Chrome */
//     background-color: red; /* Cambia el color del progreso reproducido */
//   }

// audio {
//     // Otros estilos para el elemento audio
//     width: 550px;
//     height: 30px;
//     // Estilos para el tag de progreso del audio
//     &::-webkit-media-controls-panel {
//       &::-webkit-media-controls-current-time-display {
//         // Estilos específicos para el tiempo actual en la barra de progreso
//         color: red; // Ejemplo: Cambia el color a rojo
//       }
  
//       &::-webkit-media-controls-time-remaining-display {
//         // Estilos específicos para el tiempo restante en la barra de progreso
//         color: blue; // Ejemplo: Cambia el color a azul
//       }
  
//       &::-webkit-media-controls-seek-back-button {
//         // Estilos específicos para el botón de retroceso en la barra de progreso
//         display: none; // Ejemplo: Oculta el botón de retroceso
//       }
  
//       &::-webkit-media-controls-seek-forward-button {
//         // Estilos específicos para el botón de avance en la barra de progreso
//         display: none; // Ejemplo: Oculta el botón de avance
//       }
//     }
//   }
