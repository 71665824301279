@import "../../styles/variables";

.badge-wrapper {
    border-radius: 100px;
    font-weight: 700;
    padding: 4px 8px;
    white-space: nowrap;

    @at-root #{&}__primary {
        background: $turquoise_10;
        color: $primary_color;
    }

    @at-root #{&}__secondary {
        background: #eee;
        color: $secondary_color;
    }

    @at-root #{&}__warning {
        background: $orange_40;
        color: $warning_color;
    }

    @at-root #{&}__danger {
        background: $red_40;
        color: $danger_color;
    }

    @at-root #{&}__success {
        background: $green_40;
        color: $success_color;
    }

    @at-root #{&}__muted {
        background: #ddd;
        color: $muted_color;
    }

    @at-root #{&}__normal {
        @extend .badge-wrapper;
    }

    @at-root #{&}__small {
        font-size: 10px;
        padding: 2px 6px;
    }
}