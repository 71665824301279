@import "../../styles/variables";

.section-card {
    background-color: $white;
    padding: 10px;
    border-radius: 0.7rem;
    // border: 1px solid rgba(0, 0, 0, 0.175);
    // border: 1px solid $turquoise_40;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 4px 8px 2px rgb(0 63 128 / 10%);


    @at-root #{&}-shrink {
        height: 60px;
        overflow: hidden;
    }
}

.section-card-aids {
    background-color: $white;
    padding: 15px;
    border-radius: 0.7rem;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 4px 8px 2px rgb(0 63 128 / 10%);

    @at-root #{&}-shrink {
        height: 60px;
        overflow: hidden;
    }
}
