@import "../../styles/variables";

.dropdown,
.dropdown-center,
.dropend,
.dropend-center {

    .dropdown-menu {
        display: flex;
        flex-direction: column;
        background-color: $white;
        border-radius: $border_radius_normal;
        border: 1px solid #ccc;
        user-select: none;
        transform: scale(0);
        white-space: nowrap;
        box-shadow: $box_shadow;
        z-index: 1000;

        .dropdown-item {
            display: flex;
            align-items: center;
            cursor: pointer;

            svg {
                margin-left: 6px;
                width: 14px;
            }

            a {
                text-decoration: none;
                color: $black;
            }

            &:hover {
                background-color: $primary_color !important;
                color: $white !important;
            }
        }
    }


}