.text-label {
    margin-left: 0.25rem !important;
    @extend .text-secondary;
}

.bg-orange_40 {
    background-color: $orange_40;
}

.bg-light {
    background-color: $gray_5 !important;
}

.nowrap {
    white-space: nowrap !important;
}

.text-ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
}

.bg-primary-lighter {
    background-color: rgba($turquoise_80, $alpha: 0.1);
}

.bg-primary-light {
    background-color: rgba($turquoise_80, $alpha: 0.2);
}

.text-title {
    color: $primary_color;
    font-weight: 700;
}

.text-muted {
    color: $muted_color !important;
}

.pointer {
    cursor: pointer !important;
}

.no-resize {
    resize: none;
}

.no-decoration {
    text-decoration: none;
}

.no-user-select {
    user-select: none;
}

.message-icon {
    width: 26px;
    height: 20px;
    position: relative;
    border-radius: 5px;
    background-color: $primary_color;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    font-weight: bold;
    user-select: none;
    cursor: pointer;
    z-index: 10;
    font-size: 14px;

    &::after {
        content: "";
        position: absolute;
        bottom: -5px;
        right: 0;
        width: 0;
        height: 0;
        border-top: 10px solid $primary_color;
        border-left: 10px solid transparent;
    }
}

.hoverable {
    color: #aaa !important;

    &:hover,
    > {
        color: $primary_color !important;
    }
}

.bg-oi {
    background-color: #F3C68240;
}

.bg-od {
    background-color: #D6F3F7;
}

.card-columns {
    column-count: 2;
    column-gap: 20px;
    flex: 1;

    .item {
        color: #6E6F7C !important;
        display: block;
        padding: 10px 0;
        width: 100%;
        -webkit-column-break-inside: avoid;
        /* Chrome, Safari, Opera */
        page-break-inside: avoid;
        /* Firefox */
        break-inside: avoid;
        /* IE 10+ */
    }
}

.required::after{
    content: ' *';
    color: hsl(0, 100%, 50% / 0.7);
}