.surgery-header {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 3rem;
    z-index: 50;
    border-bottom: 1px solid rgba(0, 0, 0, 0.175);
}

.surgery-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.175);
    padding: 10px 3rem;
    width: 100%;
    align-items: center;
    display: flex;
}