@import "../../styles/variables";

.textfield-modal {
    display: flex;
    flex-direction: column;

    @at-root #{&}__input {
        display: flex !important;
        gap: 5px;
        min-height: 28.8px;
        justify-content: flex-start;
        overflow-x: auto;
        border: 1px solid #ced4da;
        border-radius: $border_radius_normal;
        padding: 4px;
        white-space: nowrap;

        &.no-border-bottom {
            border-bottom: 0;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    @at-root #{&}__textarea {

        &.no-border-top {
            border-top-right-radius: 0;
            border-top-left-radius: 0;
        }
    }
}

.textfield-badge {
    background-color: #FFF3CD;
    border-radius: $border_radius_normal;
    color: $muted_color;
    padding: 0 6px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: fit-content;
}

.disabled{
    background-color: #F5F7FA;
}