@import "../../../styles/variables";

.home {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 50px;

    @at-root #{&}-account {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 200px;
        height: 70px;
        color: #6E6F7C;
        background-color: $white;
        background-image: url("../../../assets/cloud.svg");
        background-repeat: no-repeat;
        background-position: left;
        border: 1px solid #ccc;
        border-radius: $border_radius_large;
        user-select: none;
        cursor: pointer;


        &:hover {
            background-color: $darkblue_5;
        }

        &:focus-within {
            background-color: $darkblue_5;
        }

        @at-root #{&}__roles {
            width: 40px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    @at-root #{&}-workspace {
        padding: 20px;
        border-radius: $border_radius_large;
        background-color: $turquoise_20;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 400px;
    }

    @at-root #{&}__dropdown-menu {
        &.dropdown-menu {
            opacity: 0;
            transition: opacity 500ms ease-in-out;
            border: 0 !important;
            text-transform: uppercase;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15) !important;
            overflow: hidden !important;
            padding: 0 !important;
            width: 180px;
            border-radius: $border_radius_large !important;

            .home-dropdown-item {
                padding: 10px !important;
                color: #444 !important;
                transition: all 150ms ease-in-out !important;
                justify-content: center;
            }

            // &.dropdown-menu .dropdown-item:hover, &.dropdown-menu .home-dropdown-item:hover {
            //     background-color: rgba($primary_color, 0.05) !important;
            //     color: #444 !important;
            // }

            .home-dropdown-item:not(:last-child) {
                border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            }

            &.show,
            &.show-menu {
                opacity: 1;
            }
        }

    }
}