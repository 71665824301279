@import "../../../../../styles/variables";

.preoperative {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.nav-tabs {
    background-color: $turquoise_20 !important;
    border-bottom: 1px solid $turquoise_40 !important;
    border-top-left-radius: $border_radius_small;
    border-top-right-radius: $border_radius_small;
    .nav-item {
        font-weight: 700;
    }

    .active {
        border-left: 1px solid $turquoise_40 !important;
        border-right: 1px solid $turquoise_40 !important;
        border-top: 1px solid $turquoise_40 !important;
        color: $primary_color !important;
    }
}

.tabs-content {
    border-left: 1px solid $turquoise_40 !important;
    border-right: 1px solid $turquoise_40 !important;
    border-bottom: 1px solid $turquoise_40 !important;
    border-bottom-left-radius: $border_radius_small;
    border-bottom-right-radius: $border_radius_small;
    width: 100%;
    padding: 40px 30px;
    background-color: $white;
    display: flex;
    flex-direction: column;
}

.select-option-item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $gray_40;
    width: 100%;
    padding: 10px 5px;
}