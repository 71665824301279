@import "../../styles/variables";

.table-wrapper-accordion {
    width: 100%;
    border-radius: $border_radius_large;
    border-collapse: collapse;
    overflow: hidden;
    z-index: 10;
    background-color: $white;

    thead tr th{
        color: #00B4CC;
    }
    tr {
        // border-bottom: 1px solid #eee;

        &:hover {
            background: #f9f9f9;
        }
    }
    th,
    td {
        padding: 8px 10px;
        color: #6E6F7C;
    }

    &.compact {
        td {
            font-size: 1rem;
            padding: 2px 8px;
        }
    }

    @at-root #{&}-primaryAccordion {
        thead tr th {
            // background-color: $turquoise_10;
            color: #00B4CC;
            font-weight: 700;
            font-size: 1.2rem;
            text-transform: none;
        }
    }
}

.table-pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    @at-root #{&}__total {
        font-weight: 700;
        font-size: 1.2rem;
        color: #666;
    }

    @at-root #{&}__controls {
        display: flex;
        align-items: center;

        svg {
            color: $primary_color;
        }

        .number {
            font-weight: 700;
            font-size: 1.2rem;
            margin: 0 10px;
            color: #666;
        }
    }
}