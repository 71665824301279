@import "../../../../../styles/variables";


.history {
    height: 100%;
    width: 30%;
    padding: 20px;
    transition: all 500ms;
    transition-delay: 300ms;
    display: flex;
    flex-direction: column;
    border-left: 4px solid #00B4CC;
    overflow-y: auto;
    background-color: $white;
}

.view-more {
    width: 100%;
    background-color: white;
    box-shadow: 0 -10px 20px 20px white;
    height: 0;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
}

.title {
    color: $darkblue_80;
}

.textTableAccordion {
    color: $darkblue_100 !important;
    font-weight: bold;
}

.svgHoverIcons:hover {
    filter: brightness(0) saturate(100%) invert(52%) sepia(85%) saturate(1908%) hue-rotate(149deg) brightness(95%) contrast(101%);
}

.cardTabs {
    padding: 20px 10px;
    ;
    border-radius: 10px;
    background-color: $turquoise_10;
    align-items: center;
    box-shadow: 2px 4px 8px 2px #003F800A;
    box-shadow: 2px 2px 12px 2px #003F8014;
    margin-top: 20px;
}

.cardTabs>div {
    text-align: center;
    // gap: 16px;
    justify-content: space-between;
}

.sectionTitleTab {
    justify-content: space-between;
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.transitionBody {
    transition: all 500ms;
    transition-delay: 300ms;
}

.backgroundColorOpen {
    background-color: #E6F8FA;
}

.textGray100 {
    color: $gray_100 !important;
}

.my-rdp {
    --rdp-cell-size: 34px !important;
    --rdp-accent-color: #D6F3F7 !important;
    --rdp-background-color: #D6F3F7 !important;
    --rdp-caption-font-size: 17px !important;
    color: #6E6F7C !important;
    text-transform: capitalize !important;
}

.rdp-day_selected {
    color: #6E6F7C !important;
}

.rdp-head_row {
    color: #CBCBD0 !important;
}

.rdp-nav_icon {
    height: 15px !important;
}

.dayPickerStyles {
    position: absolute;
    background: white;
    border: 1px solid #ccc;
    border-radius: 15px;
    // top: 250px;
    right: 6px !important;
}

.rdp-dropdown {
    color: #6E6F7C !important;
    padding: 5px !important;
    font-size: 16px !important;
    text-transform: capitalize !important;
}

.rdp-head_row,
.rdp-day {
    font-size: 15px !important;
}

.tableStyle {
    box-shadow: 0px 1px 3px #ccc !important;
    margin-bottom: 17px !important;
}

.dropdown-item:hover,
.dropdown-item:focus {
    background-color: transparent !important;
}

#dropdownMenuHistory {
    border: 0px;
    background: transparent;
}

#dropdownMenuHistory:focus-visible {
    outline: none;
}

.tableHoverNone:hover {
    background-color: transparent !important;
}

.fs10 {
    font-size: 10px;
}

.fs13 {
    font-size: 13px;
}

.textAguaMarine {
    color: #00B4CC;
}

.multiline-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
}