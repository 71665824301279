@import "../../styles/variables";

.checkbox {
    display: flex;
    position: relative;
    cursor: pointer !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: $border_radius_normal;
    align-items: center;


    &.with-shadow {
        background-color: rgba($primary_color, $alpha: 0.1);
        padding: 5px 10px;
    }

    .form-check-input {
        border: 2px solid $primary_color;
        width: 12px;
        height: 12px;
        flex-shrink: 0;

    }



}