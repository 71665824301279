.columns {
  column-count: 1;
  column-gap: 30px;
  flex: 1;

  .item {
    color: #6e6f7c !important;
    display: inline;
    padding: 10px 0;
    width: 100%;
    -webkit-column-break-inside: auto;
    /* Chrome, Safari, Opera */
    page-break-inside: auto;
    /* Firefox */
    break-inside: auto;
    /* IE 10+ */
  }

  .block-item {
    display: block;
    width: 100%;
    -webkit-column-break-inside: avoid;
    /* Chrome, Safari, Opera */
    page-break-inside: avoid;
    /* Firefox */
    break-inside: avoid;
    /* IE 10+ */
  }
}

.column {
  display: flex;
  flex-direction: column;

  .item {
    color: #6e6f7c !important;
    display: block;
    padding: 0;
    margin: 10px 0;
  }
}

.printable {
  width: 100%;
  display: flex;
  flex-direction: column;
  -webkit-column-break-inside: avoid-column;
  break-inside: avoid;

  *:not(h1):not(h2):not(h3):not(h4) {
    font-size: 1.5rem !important;
  }
}

.print-header {
  width: 100%;

  *:not(h1):not(h2):not(h3):not(h4) {
    font-size: 1.5rem !important;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    print-color-adjust: exact;
    margin: 0;
  }

  .page-break {
    display: block;
    break-inside: avoid;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 6mm 10mm;
}
