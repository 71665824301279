@import "../../../../styles/variables";


.surgeryBody {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    background-color: $turquoise_5;
}

.widthBody {
    width: 70%;
}

.bodyHeight {
    height: calc(100vh - 28.5rem);
    overflow-y: auto;
    overflow-x: hidden;
}

.bodyHeightDoctorsSheet {
    height: calc(100vh - 24.5rem);
    // display: flex;
    overflow-y: auto;
    overflow-x: hidden;
}


.bodyHeightAdjusted {
    max-height: calc(100vh - 30rem);
    overflow-y: auto;
    overflow-x: hidden;
}


.cardGeneralInfoSurgery {
    background-color: #e8e8ea8a;
    border-radius: 6px;
    padding: 6px 4px;
}


.bodyRegisterInfo {
    min-height: calc(100vh - 190px);
    overflow: auto;
}

.bodyRegisterInfoReading {
    min-height: calc(100vh - 207px);
    overflow: auto;
}

.sectionDisabled {
    display: none
}

.backgroundSpaceSection {
    background: #fff;
    border-left: 4px solid #00B4CC;

}

.cardLotsSection {
    border: 2px solid $turquoise_20;
    background-color: $turquoise_20;
    padding: 0.5rem;
    border-radius: 6px;
}

.blueFilter {
    filter: invert(63%) sepia(72%) saturate(4202%) hue-rotate(149deg) brightness(96%) contrast(101%);
}

.fullScreen {
    height: 100%;
    width: 100%;
}

.subtitleColor {
    color: $darkblue_60
}

.lineCards {
    margin: 0.2rem 0px;
    margin-bottom: 1rem;
    color: #00B4CC;
}

.subTitleTable {
    background-color: $gray_20;
}

.evidenceContainer {
    width: auto;
    overflow-x: auto;
}

.closeButtonEvidenceImage {
    position: absolute;
    top: 85%;
    left: 85%;
}

.controls {
    display: flex;
    align-items: center;
    width: 100%;
    max-height: 90px;
    background: white;
    padding: 1rem 3rem;
    z-index: 50;
    border-top: 1px solid rgba(0, 0, 0, 0.175);
}

.alertRowColor {
    background-color: #d51b2165;
}

.attentionSurgeryControls {
    @at-root #{&}-main {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    @at-root #{&}-tabs {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    @at-root #{&}-tab {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px;
        flex: 1;
        color: $primary_color;
        border: 2px solid $turquoise_20;
        border-radius: $border_radius_normal;
        font-weight: 700;
        background-color: $turquoise_20;
        cursor: pointer;
        user-select: none;
        white-space: nowrap;
        padding: 0 10px;

        &.active,
        &:hover {
            background-color: $primary_color;
            border: 2px solid $primary_color;
            color: $white;
        }

        svg {
            width: 20px;
            margin-right: 5px;
        }
    }

    @at-root #{&}-quest {
        display: flex;
        align-items: flex-end;
        height: 40px;
    }
}