@import "../../../styles/variables";

.medicgate {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 50px;

    @at-root #{&}-accordion {

        @at-root #{&}__list {
            display: flex;
            flex-direction: column;
            margin: 0;

            li,
            a {
                color: $muted_color;
                list-style: none;
                font-size: 1.1rem;
                padding: 5px 0;
                cursor: pointer;
                text-decoration: none;

                &:hover {
                    color: $primary_color;
                }
            }
        }
    }
}